ul.navbar {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #708090;
    align-items: center;
    padding: 0px 5px;
    height: 64px;
    z-index: 4;
    position: fixed;
    top: 0;
    width: -webkit-fill-available;
  }

.logo {
    height: 50px;
    border-radius: 10px;
    background-color: transparent;
    padding: 2px;
}

div.button {
    display: flex;
    align-items: center;
    padding: 4px;
    height: inherit;
}

a.navbar {
    color: white;
    text-align: center;
    padding: 3px;
    text-decoration: none;
    align-items: center;
    font-size: 26;
}

.button:hover {
    background-color: #36454f;
    cursor: pointer;
    border-radius: 10px;
}

#no-hover:hover{
    background-color: transparent;
}

#right {
    margin-left: auto;
}

.page-title {
    font-size: 30px;
    font-weight: 900;
}