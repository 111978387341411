div.download-file {
    border: 1px solid #333;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

div.download-file:hover {
    cursor: pointer;
}

div.doc-name{
    font-size: 12px;
}