div.course-card {
    max-width:300px;
    margin: 10px;
    background-color: #dedede;
    border: 2px solid #909090;
    border-radius: 10px;
}

div.course-card img {
    border-radius: 8px 8px 0px 0px;
}

div.course-card:hover {
    cursor: pointer;
}

div.prior-info {
    display: flex;
    padding: 0px 2px;
    padding-bottom: 2px;
    border-bottom: 1px solid #bdbdbd;
}

div.rating-data {
    padding-right: 3px;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}

div.additional-info {
    display: flex;
    align-items: center;
}

.lenguaje-icon {
    filter: grayscale(1);
    padding-left: 2px;
}

div.progress-div {
    margin-left: auto;
}

.idioma-icon {
    border-radius: 0;
}